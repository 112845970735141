import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, FormControl, MenuItem, Select, InputLabel } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { yearList } from '../data/constants/dates';
import { brandList } from '../data/constants/selects';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
  totalSumStyle: {
    backgroundColor: '#e0f0ff',
    color: '#000',
    fontWeight: 700
  }
});

const AdminViewTableDebetList = (props) => {

  const {
    data,
    month,
    year,
    managerId,
    managerList,
    userData,
    brand,
    handleChangeMonth,
    handleChangeYear,
    handleChangeManager,
    handleChangeBrand
  } = props

  const classes = useStyles();

  const debtPrevMonthSum = data.reduce((sum, i) => sum + i.debtPrevMonth, 0)
  const realizationSum = data.reduce((sum, i) => sum + i.realization, 0)
  const payedSum = data.reduce((sum, i) => sum + i.payed, 0)
  const totalDebtSum = data.reduce((sum, i) => sum + i.totalDebt, 0)

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="brand label">Бренд</InputLabel>
          <Select
            value={brand}
            onChange={handleChangeBrand}
          >
            {brandList.map(item => {
              if (item.value === 'all') return
              return <MenuItem key={`brand-${item.value}`} value={item.value}>{item.label}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="month label">Місяць</InputLabel>
          <Select
            value={month}
            onChange={handleChangeMonth}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Рік</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            {yearList.map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="manager label">Менеджер</InputLabel>
          <Select
            value={managerId}
            onChange={handleChangeManager}
          >
            {managerList.map(item => {
              return <MenuItem key={item.id} value={item.id}>{item.managerName}</MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table" >
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Місяць</TableCell>
              <TableCell>Рік</TableCell>
              <TableCell>Контрагент</TableCell>
              <TableCell>Менеджер</TableCell>
              <TableCell>Борг за попередній місяць</TableCell>
              <TableCell>Реалізація</TableCell>
              <TableCell>Оплачено</TableCell>
              <TableCell>Загал. борг</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{debtPrevMonthSum}</TableCell>
              <TableCell>{realizationSum}</TableCell>
              <TableCell>{payedSum}</TableCell>
              <TableCell>{totalDebtSum}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .sort((a, b) => b.totalDebt - a.totalDebt)
              .map((row) => (
                <TableRow key={row.itemId}>
                  <TableCell>{row.itemId}</TableCell>
                  <TableCell>{row.month}</TableCell>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{row.contragent}</TableCell>
                  <TableCell>{row.manager.managerName}</TableCell>
                  <TableCell>{row.debtPrevMonth}</TableCell>
                  <TableCell>{row.realization}</TableCell>
                  <TableCell>{row.payed}</TableCell>
                  <TableCell>{row.totalDebt}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminViewTableDebetList;
