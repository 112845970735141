import axios from 'axios';
import { BACKEND_DOMAIN } from "../data/constants/statuses"

export const getUserData = async () => {

    const res = await axios
        .get(`https://geolocation-db.com/json/`).then(response => {
            return response.data
        });
    return res;
}

export const addUserLogDateApi = async (userData, page, itemData) => {

    const { token, managerId, username } = userData

    // const userAddressData = await getUserData()
    // const userCountry = userAddressData.IPv4 || 'unknown'
    const data = { manager: managerId, page, userCountry: username, data: itemData }

    axios
        .post(`${BACKEND_DOMAIN}/user-logs`, data,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
}
