import { getMainManagerListApi, getManagerSaleListApi, updateManagerSaleFieldByIdApi } from "../../api/managerListApi"
import { MAIN_MANAGER_LIST_LOAD_SUCCESSFUL, MANAGER_LIST_LOAD_SUCCESSFUL, MANAGER_SALE_LIST_LOAD_SUCCESSFUL } from "../reducers/managerListReducer"
import { getAllManagerSaleListApi, getAllManagerListApi } from './../../api/managerListApi';
import { setSnackbarAction } from './snackbarActions';

export const loadManagerListAction = (userData) => {
    return async (dispatch) => {
        const resManagers = await getAllManagerListApi(userData)
        dispatch({
            type: MANAGER_LIST_LOAD_SUCCESSFUL,
            payload: resManagers,
        })
    }
}

export const loadMainManagerListAction = (userData, location) => {
    return async (dispatch) => {
        const resManagers = await getMainManagerListApi(userData, location)
        dispatch({
            type: MAIN_MANAGER_LIST_LOAD_SUCCESSFUL,
            payload: resManagers,
        })
    }
}

export const getManagerSaleListAction = (userData, year, brand) => {
    return async (dispatch) => {
        const res = await getManagerSaleListApi(userData, year, brand)
        dispatch({
            type: MANAGER_SALE_LIST_LOAD_SUCCESSFUL,
            payload: res,
        })
    }
}

export const getAllManagerSaleListAction = (userData, year, month, brand) => {
    return async (dispatch) => {
        const res = await getAllManagerSaleListApi(userData, year, month, brand)
        dispatch({
            type: MANAGER_SALE_LIST_LOAD_SUCCESSFUL,
            payload: res,
        })
    }
}

export const updateManagerSaleFieldAction = (fieldId, data, userData, year, brand) => {
    return async (dispatch) => {
        const res = await updateManagerSaleFieldByIdApi(fieldId, data, userData)
        if (res !== 200) return dispatch(setSnackbarAction(true, "error", "Помилка! Дані не збережені."))
        if (res === 200) {
            dispatch(getAllManagerSaleListAction(userData, year, 0, brand))
            return dispatch(setSnackbarAction(true, "success", "Дані збережені."))
        }
    }
}