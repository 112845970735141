import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { allManagerListSelector } from './../data/selectors/managerListSelector';
import { loadManagerListAction } from './../data/actions/managerListActions';
import { loadManagerDebetListAction } from './../data/actions/debetsActions';
import { debetListSelector } from './../data/selectors/debetListSelector';
import AdminViewTableDebetList from './../views/AdminViewTableDebetList';
import { addUserLogDateApi } from '../api/logDataApi';
import ViewTableDebetList from '../views/ViewTableDebetList';

const DebetListReportContainer = () => {

    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const currBrand = JSON.parse(localStorage.getItem('brand'));
    const [brand, setBrand] = useState(currBrand === 'all' || currBrand === null ? 'ELEGANT' : currBrand);
    const managerId = userData.managerId
    const [month, setMonth] = useState(currentMonth === 12 ? 1 : currentMonth + 1)
    const [year, setYear] = useState(currentMonth === 12 ? currentYear + 1 : currentYear)
    const debetList = useSelector(debetListSelector)

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const handleChangeBrand = (event) => {
        setBrand(event.target.value)
    }

    useEffect(() => {
        localStorage.setItem('brand', JSON.stringify(brand));
    }, [brand])

    useEffect(() => {
        dispatch(loadManagerDebetListAction(userData, year, month, managerId, brand))
        addUserLogDateApi(userData, '/debetListReport')

    }, [month, year, brand])

    return (
        <ViewTableDebetList
            data={debetList}
            month={month}
            year={year}
            managerId={managerId}
            userData={userData}
            brand={brand}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            handleChangeBrand={handleChangeBrand}
        />

    )
}

export default DebetListReportContainer
