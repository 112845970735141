import { createSelector } from 'reselect';
import date from 'date-and-time';

export const userSelector = (state) => state.authentication.user

export const userDataSelector = createSelector(
    [userSelector],
    (user) => {
        const token = user.jwt
        const managerId = user.user.manager_ids[0].id
        const managerLocale = 'uk-UA'
        const managerName = user.user.manager_ids[0].managerName
        const managerBonusPercent = user.user.manager_ids[0].bonusPercent
        const managerIdList = user.user.manager_ids
        let d = new Date();
        d.setDate(1);
        d.setMonth(d.getMonth() - 1);
        const monthData = Number(date.format(d, 'M'));
        const yearData = Number(date.format(d, 'YYYY'));
        const settingsData = { monthData, yearData }
        const role = user.user.role.name
        const username = user.user.username
        return { token, managerId, managerLocale, managerName, settingsData, managerBonusPercent, role, managerIdList, username }
    }
)

// export const adminDataSelector = createSelector(
//     [userSelector],
//     (user) => {
//         const token = user.jwt
//         let d = new Date();
//         d.setDate(1);
//         d.setMonth(d.getMonth() - 1);
//         const monthData = Number(date.format(d, 'M'));
//         const yearData = Number(date.format(d, 'YYYY'));
//         const settingsData = { monthData, yearData }
//         const role = user.user.role.name
//         const managerId = user.user.manager_id.id
//         const adminLocale = user.user.manager_id.locale
//         return { token, settingsData, role, adminLocale, managerId }
//     }
// )