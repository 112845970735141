import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { adminOrdersDataSelector } from './../data/selectors/orderListItemsSelector';
import ViewTableOrderList from './../views/ViewTableOrderList';
import { loadMonthOrderListAction } from './../data/actions/orderListActions';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { addUserLogDateApi } from '../api/logDataApi';

const OrderListPerMonthContainer = () => {


    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const currBrand = JSON.parse(localStorage.getItem('brand'));
    const [brand, setBrand] = useState(currBrand === 'all' || currBrand === null ? 'ELEGANT' : currBrand);
    const { managerId } = userData
    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)
    const { items, ordersSum } = useSelector(adminOrdersDataSelector(managerId));

    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const handleChangeBrand = (event) => {
        setBrand(event.target.value)
    }

    useEffect(() => {
        localStorage.setItem('brand', JSON.stringify(brand));
    }, [brand])

    useEffect(() => {
        dispatch(loadMonthOrderListAction(userData, year, month, brand))
        addUserLogDateApi(userData, '/orderListReport')
    }, [month, year, brand])

    return (
        <ViewTableOrderList
            items={items}
            month={month}
            year={year}
            brand={brand}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            handleChangeBrand={handleChangeBrand}
        />
    )
}

export default OrderListPerMonthContainer
