import { AppBar, makeStyles, Toolbar, Typography, Button, Link, Divider, Grid, Hidden, Menu, MenuItem } from "@material-ui/core"
import { useSelector } from 'react-redux';
import { userDataSelector } from "../data/selectors/userDataSelector";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    managerName: {
        fontSize: 14,
        lineHeight: 1.2,
        [theme.breakpoints.up('md')]: {
            fontSize: 14
        },
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.4,
        },
    },
    logoText: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.2,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            fontSize: 16
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: 1.4
        },
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: '#ffffff',
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        marginRight: theme.spacing(2),
        // lineHeight: 1.6
    },
    linkMob: {
        color: '#3f51b5',
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        marginRight: theme.spacing(2),
        // lineHeight: 1.6
    },
    button: {
        color: '#ffffff',
        background: "#7282da",
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        marginRight: theme.spacing(2),
        // lineHeight: 1.6
    },
    menu: {
        position: 'relative',
        top: 5,
        [theme.breakpoints.down('sm')]: {
            top: -2,
            left: 10
        },
    }
}));

const HeaderLayout = () => {
    const classes = useStyles();

    const userData = useSelector(userDataSelector)

    const preventDefault = (event) => event.preventDefault();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const screenWidth = window.innerWidth;
    // console.log(`Ширина экрана: ${screenWidth}px`);

    useEffect(() => {
    }, [screenWidth]);

    return (
        <AppBar position="fixed">
            <Toolbar>
                {screenWidth < 800 && <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Typography className={classes.logoText}>
                            Perolite<br />App
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "center" }}>
                        <Button aria-controls="simple-menu" align='right' aria-haspopup="true" onClick={handleClick} className={classes.button}>
                            МЕНЮ
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>
                                <Link href="/" className={classes.linkMob}>
                                    Дашборд
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/newPromocodes" className={classes.linkMob}>
                                    Нові&nbsp;промокоди
                                </Link>
                            </MenuItem>
                            {/* <MenuItem onClick={handleClose}>
                                <Link href="/bonusForm" className={classes.linkMob}>
                                    Бонуси&nbsp;лікарів
                                </Link>
                            </MenuItem> */}
                            {/* <MenuItem onClick={handleClose}>
                                <Link href="/bonusReport" className={classes.linkMob}>
                                    Архів&nbsp;бонусів
                                </Link>
                            </MenuItem> */}
                            <MenuItem onClick={handleClose}>
                                <Link href="/orderListReport" className={classes.linkMob}>
                                    Замовлення
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/productQtyListReport" className={classes.linkMob}>
                                    План
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/DebetListReport" className={classes.linkMob}>
                                    Дебет
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/orderForm2" className={classes.linkMob}>
                                    Форма
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/orderFormFeetcalm" className={classes.linkMob}>
                                    Форма-F
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/bonusSystem" className={classes.linkMob}>
                                    Бонуси
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/"
                                    className={classes.linkMob}
                                    onClick={() => localStorage.removeItem('user')}
                                >
                                    Вийти
                                </Link>
                            </MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="h6" className={classes.managerName}>
                            Менеджер:<br />{userData.managerName}
                        </Typography>
                    </Grid>
                </Grid>
                }
                {screenWidth >= 800 &&
                    <Grid container spacing={1}>
                        <Grid item xs={1} md={1}>
                            <Typography className={classes.logoText}>
                                Perolite<br />App
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={8} className={classes.menu}>
                            <Link href="/" className={classes.link}>
                                Дашборд
                            </Link>
                            <Link href="/newPromocodes" className={classes.link}>
                                Нові&nbsp;промокоди
                            </Link>
                            {/* <Link href="/bonusForm" className={classes.link}>
                                Бонуси&nbsp;лікарів
                            </Link> */}
                            <Hidden mdUp><br /></Hidden>
                            {/* <Link href="/bonusReport" className={classes.link}>
                                Архів&nbsp;бонусів
                            </Link> */}
                            <Link href="/orderListReport" className={classes.link}>
                                Замовлення
                            </Link>
                            <Link href="/productQtyListReport" className={classes.link}>
                                План
                            </Link>
                            <Link href="/DebetListReport" className={classes.link}>
                                Дебет
                            </Link>
                            <Link href="/orderForm2" className={classes.link}>
                                Форма
                            </Link>
                            <Link href="/orderFormFeetcalm" className={classes.link}>
                                Форма-F
                            </Link>
                            <Link href="/bonusSystem" className={classes.link}>
                                Бонуси
                            </Link>
                            <Link href="/"
                                className={classes.link}
                                onClick={() => localStorage.removeItem('user')}
                            >
                                Вийти
                            </Link>
                        </Grid>
                        <Grid item xs={5} md={3}>
                            <Typography variant="h6" align='right' className={classes.managerName}>
                                Менеджер:<br />{userData.managerName}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Toolbar>
        </AppBar>
    )
}

export default HeaderLayout