import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import { updateManagerSaleFieldAction } from './../data/actions/managerListActions';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  noPadding: {
    padding: "14px 2px",
    textAlign: "center"
  },
  isTurnover: {
    backgroundColor: '#e0f0ff'
  },
  isBonus: {
    backgroundColor: '#e0fff2'
  },
  isBold: {
    fontWeight: 'bold'
  },
  customTable: {
    padding: "6px 0px 6px 8px" // <-- arbitrary value
  },
  inputRoot: {
    fontWeight: 'bold'
  }
});

const DashboardTableRow = (props) => {

  const {
    items,
    title,
    fieldName,
    isEditable,
    userData,
    isAutoSum,
    // setItemValues,
    // itemValues,
    rowColor,
    rowFont,
    year,
    brand
  } = props
  const dispatch = useDispatch()
  const classes = useStyles();

  const [cellValue, setCellValue] = useState();
  const [editItemId, setEditItemId] = useState(null);
  const [editFieldData, setEditFieldData] = useState();

  const handleClickCell = (event, id, value) => {
    if (Number(event.target.id) === id) {
      setEditItemId(id)
      setCellValue(value)
    }
  }

  const handleEditFieldChange = (event) => {
    event.preventDefault();
    const targetValue = Number(event.target.value);
    setCellValue(targetValue)
  };

  const handleKeyDown = (event) => {
    if (event.keyCode == 13) {
      console.log('value', event.target.value);
      const targetName = event.target.name;
      const newFieldData = { ...editFieldData };
      newFieldData[targetName] = cellValue;
      dispatch(updateManagerSaleFieldAction(editItemId, newFieldData, userData, year, brand));
    }
  };

  const colorStyle = rowColor === 'blue' ? classes.isTurnover : classes.isBonus
  const cellStyle = rowFont === 'bold' ? classes.isBold : classes.tableCell

  return (
    <TableRow className={colorStyle}>
      <TableCell component="tr" scope="row" className={cellStyle}>
        {title}
      </TableCell>
      {items.map(item => {
        return (
          <React.Fragment key={item.id}>
            {!isEditable && <TableCell component="tr" scope="row" size="medium" classes={{ root: classes.customTable }}>
              <TextField
                value={item[fieldName]}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  classes: { root: cellStyle, input: classes.noPadding }
                }}
              />
            </TableCell>
            }
            {isEditable && <TableCell
              component="tr"
              scope="row"
              size="medium"
              onClick={(e) => handleClickCell(e, item.id, item[fieldName])}
              classes={{ root: classes.customTable }}
            >
              {editItemId == item.id ? (<TextField
                id={item.id}
                name={fieldName}
                value={cellValue}
                onChange={(e) => handleEditFieldChange(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                placeholder={0}
                variant="outlined"
                fullWidth
                InputProps={{
                  classes: { root: cellStyle, input: classes.noPadding }
                }}
              />
              ) :
                <TextField
                  id={item.id}
                  name={fieldName}
                  value={item[fieldName]}
                  placeholder={0}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: { root: cellStyle, input: classes.noPadding }
                  }}
                />
              }
            </TableCell>
            }
          </React.Fragment>
        )
      })}
    </TableRow>
  );
}

export default DashboardTableRow