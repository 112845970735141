import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ViewTableManagersTotalSale from '../views/ViewTableManagersTotalSale';
import { loadStartAction } from './../data/actions/loadStartActions';
import { managerItemSalesTotalSumSelector, managerSalesTableSelector } from '../data/selectors/managerListSelector';
import { getManagerSaleListAction } from '../data/actions/managerListActions';
import { userDataSelector } from './../data/selectors/userDataSelector';
import { addUserLogDateApi } from '../api/logDataApi';
import { getProductQtyListAction } from '../data/actions/productQtyListActions';
import { productQtyListTableMonthSelector } from '../data/selectors/productQtySelector';


const DashboardManagerSalesContainer = () => {

    const userData = useSelector(userDataSelector)

    const yearData = userData.settingsData.yearData
    const managerId = userData.managerId

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [year, setYear] = useState(yearData)
    const currBrand = JSON.parse(localStorage.getItem('brand'));
    const [brand, setBrand] = useState(currBrand === 'all' || currBrand === null ? 'ELEGANT' : currBrand);

    const dispatch = useDispatch()

    const productQtyListByMonth = useSelector(productQtyListTableMonthSelector)
    const managerItemSalesTotalSum = useSelector(managerItemSalesTotalSumSelector(productQtyListByMonth, year, brand))

    const data = { userData, year, month: 0, managerIdData: managerId }

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const handleChangeBrand = (event) => {
        setBrand(event.target.value)
    }

    useEffect(() => {
        if (!isFirstRender) {
            localStorage.setItem('brand', JSON.stringify(brand));
            window.location.reload(); // Перезагрузка страницы при изменении бренда
        } else {
            setIsFirstRender(false);
        }
    }, [brand])

    useEffect(() => {
        dispatch(getManagerSaleListAction(userData, year, brand))
        addUserLogDateApi(userData, '/')
    }, [year, brand])

    useEffect(() => {
        dispatch(getProductQtyListAction(data))
    }, [year])

    return (
        // <div></div>
        <ViewTableManagersTotalSale
            items={managerItemSalesTotalSum}
            year={year}
            brand={brand}
            handleChangeYear={handleChangeYear}
            handleChangeBrand={handleChangeBrand}
        />
    )
}

export default DashboardManagerSalesContainer
