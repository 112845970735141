import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { loadBonusDataByPeriodAction, loadOrderDataByPeriodAction } from '../data/actions/bonusSystemActions';
import { getTotalBonusSumsByYearAndMonth, getTotalOrderSumsByYearAndMonth } from './../data/selectors/bonusSystemSelector';
import { CircularProgress } from '@material-ui/core';
import { addUserLogDateApi } from '../api/logDataApi';
import BonusPaymentsList from '../views/bonusSystem/BonusPaymentsList';

const BONUS_PERCENTAGE = 10;

const BonusSystemPaymentsContainer = () => {
    const dispatch = useDispatch();

    const userData = useSelector(userDataSelector);

    const currBrand = JSON.parse(localStorage.getItem('brand'));
    const [brand, setBrand] = useState(currBrand === 'all' || currBrand === null ? 'ELEGANT' : currBrand);
    const [year, setYear] = useState(userData.settingsData.yearData || "");
    const [month, setMonth] = useState(userData.settingsData.monthData || "");
    const [managerFilter, setManagerFilter] = useState('');
    const [loading, setLoading] = useState(false); // Добавьте состояние загрузки

    // const { promocodeOrdersSum, bonusSum, bonusRecommendedSum } = useSelector(getTotalBonusSumsByYearAndMonth(year, month, managerFilter));
    // const greyZoneOrdersSum = useSelector(getTotalOrderSumsByYearAndMonth(year, month, managerFilter));

    const selectedManagerId = managerFilter;

    const handleChangeBrand = (value) => {
        setBrand(value)
    }

    useEffect(() => {
        const loadAsyncData = async () => {
            setLoading(true); // Начать загрузку
            await Promise.all([
                dispatch(loadBonusDataByPeriodAction(userData, year, month, selectedManagerId, '', brand)),
                // dispatch(loadOrderDataByPeriodAction(userData, year, month, selectedManagerId))
            ]);
            setLoading(false); // Завершить загрузку после завершения всех действий
            addUserLogDateApi(userData, '/bonusSystemPayments')
        };
        if (selectedManagerId) {
            loadAsyncData();
        }
    }, [userData, month, year, managerFilter]);

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', width: '100%', height: '100vh'
        }}>
            <CircularProgress />
        </div>
    }

    if (userData.role !== 'admins' && userData.role !== 'admin') return 'Нет доступа'

    return (
        <BonusPaymentsList
            userData={userData}
            managerFilter={managerFilter}
            setManagerFilter={setManagerFilter}
            yearFilter={year}
            setYearFilter={setYear}
            monthFilter={month}
            setMonthFilter={setMonth}
            // bonusSum={bonusSum}
            // promocodeOrdersSum={promocodeOrdersSum}
            // greyZoneOrdersSum={greyZoneOrdersSum}
            // bonusRecommendedSum={bonusRecommendedSum}
            brand={brand}
            handleChangeBrand={handleChangeBrand}
        />
    );
};

export default BonusSystemPaymentsContainer;