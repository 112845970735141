import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userDataSelector } from '../data/selectors/userDataSelector';
import { allManagerListSelector } from './../data/selectors/managerListSelector';
import { loadManagerListAction } from './../data/actions/managerListActions';
import { loadManagerDebetListAction } from './../data/actions/debetsActions';
import { debetListSelector } from './../data/selectors/debetListSelector';
import AdminViewTableDebetList from './../views/AdminViewTableDebetList';
import { addUserLogDateApi } from '../api/logDataApi';

const AdminDebetListReportContainer = () => {

    const dispatch = useDispatch()

    const userData = useSelector(userDataSelector)
    const currentMonth = userData.settingsData.monthData
    const currentYear = userData.settingsData.yearData
    const currBrand = JSON.parse(localStorage.getItem('brand'));
    const [brand, setBrand] = useState(currBrand === 'all' || currBrand === null ? 'ELEGANT' : currBrand);
    const [month, setMonth] = useState(currentMonth === 12 ? 1 : currentMonth + 1)
    const [year, setYear] = useState(currentMonth === 12 ? currentYear + 1 : currentYear)
    const [managerId, setManagerId] = useState(0)
    const [location, setLocation] = useState('uk-UA');
    const [archiveStatus, setArchiveStatus] = useState(true);
    const debetList = useSelector(debetListSelector)
    // console.log("🚀 ~ file: AdminDebetListReportContainer.js:33 ~ AdminDebetListReportContainer ~ debetList:", debetList)
    const managerList = useSelector(allManagerListSelector)


    const handleChangeMonth = (event) => {
        setMonth(event.target.value)
    }
    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }
    const handleChangeManager = (event) => {
        setManagerId(event.target.value)
    }
    const handleChangeArchive = (event) => {
        setArchiveStatus(event.target.value)
    }

    const handleChangeBrand = (event) => {
        setBrand(event.target.value)
    }

    useEffect(() => {
        localStorage.setItem('brand', JSON.stringify(brand));
    }, [brand])

    useEffect(() => {
        dispatch(loadManagerDebetListAction(userData, year, month, managerId, brand))
    }, [month, year, managerId, location, brand])

    useEffect(() => {
        dispatch(loadManagerListAction(userData, location))
        addUserLogDateApi(userData, '/admin/debetListReport')
    }, [])

    const calculateTotalDebtByContragent = (data) => {
        const totalDebt = {};

        data.forEach(item => {
            const debt = item.realization - item.payed;
            if (totalDebt[item.contragent]) {
                totalDebt[item.contragent] += debt;
            } else {
                totalDebt[item.contragent] = debt;
            }
        });

        // Фильтрация записей с долгом больше 0
        // const filteredDebt = Object.entries(totalDebt).reduce((acc, [contragent, debt]) => {
        //     if (debt) {
        //         acc[contragent] = debt;
        //     }
        //     return acc;
        // }, {});

        return totalDebt;
    };

    const totalDebtByContragent = calculateTotalDebtByContragent(debetList);
    // console.log("🚀 ~ AdminDebetListReportContainer ~ totalDebtByContragent:", totalDebtByContragent);

    // Преобразование объекта в массив и сортировка по убыванию
    const sortedArray = Object.entries(totalDebtByContragent).sort((a, b) => b[1] - a[1]);
    // console.log("🚀 ~ AdminDebetListReportContainer ~ sortedArray:", sortedArray)

    const calculateTotalDebt = (totalDebtByContragent) => {
        return Object.values(totalDebtByContragent).reduce((acc, debt) => acc + debt, 0);
    };

    // const totalDebt = calculateTotalDebt(totalDebtByContragent);
    // console.log("🚀 ~ AdminDebetListReportContainer ~ totalDebt:", totalDebt);

    return (
        <AdminViewTableDebetList
            data={debetList}
            month={month}
            year={year}
            managerId={managerId}
            managerList={managerList}
            userData={userData}
            brand={brand}
            handleChangeMonth={handleChangeMonth}
            handleChangeYear={handleChangeYear}
            handleChangeManager={handleChangeManager}
            handleChangeBrand={handleChangeBrand}
        />

    )
}

export default AdminDebetListReportContainer
