import axios from 'axios';
// import managers from './managerListData.json'
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const getManagerListApi = async (userData) => {
    const { token } = userData
    console.log("🚀 ~ file: managerListApi.js ~ line 6 ~ getManagerListApi ~ token", token)
    const res = axios
        .get(`${BACKEND_DOMAIN}/managers?_limit=-1&_locale=uk-UA`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getMainManagerListApi = async (userData, location) => {
    const { token } = userData
    const res = axios
        .get(`${BACKEND_DOMAIN}/managers?_limit=-1&manager_status=main`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAllManagerListApi = async (userData) => {
    const { token } = userData
    const res = axios
        .get(`${BACKEND_DOMAIN}/managers?_limit=-1`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getManagerSaleListApi = async (userData, year, brand) => {
    const { token, managerId, managerLocale } = userData

    const brandRes = brand === 'all' ? '' : `&brand=${brand}`

    const res = axios
        .get(`${BACKEND_DOMAIN}/managers-sales?_limit=-1&_locale=${managerLocale}&manager=${managerId}&year=${year}${brandRes}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAllManagerSaleListApi = async (userData, year, month, brand) => {
    const { token, settingsData } = userData

    const monthRes = month ? `&month=${month}` : (month === 0 ? '' : `&month=${settingsData.monthData}`);

    const yearRes = year ? year : settingsData.yearData

    const brandRes = brand === 'all' ? '' : `&brand=${brand}`

    const res = axios
        .get(`${BACKEND_DOMAIN}/managers-sales?_limit=-1&_locale=uk-UA&year=${yearRes}${monthRes}${brandRes}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const updateManagerSaleFieldByIdApi = async (fieldId, data, userData) => {
    const { token } = userData
    const res = axios
        .put(`${BACKEND_DOMAIN}/managers-sales/${fieldId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            return response.status
        });
    return res;
}