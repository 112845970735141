import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loadMainManagerListAction, getAllManagerSaleListAction } from '../data/actions/managerListActions';
import { userDataSelector } from './../data/selectors/userDataSelector';
import AdminViewTableManagersTotalSale from './../views/AdminViewTableManagersTotalSale';
import { managerListSalesTotalSumSelector } from './../data/selectors/managerListSelector';
import { addUserLogDateApi } from '../api/logDataApi';
import { getProductQtyListAction } from '../data/actions/productQtyListActions';

const AdminDashboardManagerSalesContainer = () => {
    const [totalSum, setTotalSum] = useState(0);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const dispatch = useDispatch()
    const userData = useSelector(userDataSelector)
    const [year, setYear] = useState(userData.settingsData.yearData)
    const [location, setLocation] = useState(userData.adminLocale);
    const currBrand = JSON.parse(localStorage.getItem('brand'));
    const [brand, setBrand] = useState(currBrand === 'all' || currBrand === null ? 'ELEGANT' : currBrand);
    const managerListSalesTotalSum = useSelector(managerListSalesTotalSumSelector(year, brand))

    const data = { userData, year, month: 0, managerIdData: 0 }

    const handleChangeYear = (event) => {
        setYear(event.target.value)
    }

    const handleChangeBrand = (event) => {
        setBrand(event.target.value)
    }

    useEffect(() => {
        if (!isFirstRender) {
            localStorage.setItem('brand', JSON.stringify(brand));
            window.location.reload(); // Перезагрузка страницы при изменении бренда
        } else {
            setIsFirstRender(false);
        }
    }, [brand])

    useEffect(() => {
        localStorage.setItem('location', JSON.stringify(location));
        dispatch(getAllManagerSaleListAction(userData, year, 0, brand))
        // dispatch(getProductQtyListAction(data))
    }, [year, location, brand])

    useEffect(() => {
        dispatch(loadMainManagerListAction(userData, location))
        addUserLogDateApi(userData, '/admin/dashboard')
    }, []);

    return (
        <AdminViewTableManagersTotalSale
            managers={managerListSalesTotalSum}
            userData={userData}
            setLocation={setLocation}
            location={location}
            setTotalSum={setTotalSum}
            totalSum={totalSum}
            year={year}
            brand={brand}
            handleChangeYear={handleChangeYear}
            handleChangeBrand={handleChangeBrand}
        />
    )
}

export default AdminDashboardManagerSalesContainer
