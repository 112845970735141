export const getSalesTotals = (selectedManagerId, data, selectedMonth) => {
    let shopSum = 0;
    let aptekSalesSum = 0;
    let clinicSalesSum = 0;
    let minOklad = 0;
    let managerBonusPercent = 0;

    // const totalCompletePercent = Number(totalСomplPercent)

    data.forEach(manager => {
        if (selectedManagerId && manager.managerId !== selectedManagerId) {
            return;
        }

        manager.monthlySales.forEach(sale => {
            if (selectedMonth && sale.month !== selectedMonth) {
                return;
            }
            managerBonusPercent = manager.managerBonusPercent
            const shopSumItem = sale.saleSum + sale.otherSale
            shopSum += shopSumItem || 0;
            aptekSalesSum += sale.aptekSale || 0;
            clinicSalesSum += sale.clinicSale || 0;
            minOklad += sale.minOklad || 0;
        });
    });

    // let currentBonusPercent = 0;

    // switch (true) {
    //     case totalCompletePercent >= 100:
    //         currentBonusPercent = 7
    //         break;
    //     case totalCompletePercent >= 90:
    //         currentBonusPercent = 6
    //         break;
    //     case totalCompletePercent < 90:
    //         currentBonusPercent = 5
    //         break;

    //     default:
    //         break;
    // }

    const totalVal = shopSum + clinicSalesSum + aptekSalesSum

    const bonusRes = Math.round(totalVal * (managerBonusPercent / 100))

    const totalBonus = bonusRes + minOklad

    return { shopSum, aptekSalesSum, clinicSalesSum, totalVal, minOklad, bonusRes, totalBonus };
};