import { AppBar, makeStyles, Toolbar, Typography, Button, Link, Divider, Grid, MenuItem, Menu } from "@material-ui/core"
import DashboardManagerSalesContainer from '../containers/DashboardManagerSalesContainer';
import { useSelector } from 'react-redux';
import { userDataSelector } from "../data/selectors/userDataSelector";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    managerName: {
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: '#ffffff',
        marginRight: theme.spacing(2),
        // lineHeight: 1.6
    },
    linkMob: {
        color: '#3f51b5',
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        marginRight: theme.spacing(2),
        // lineHeight: 1.6
    },
    button: {
        color: '#ffffff',
        background: "#7282da",
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        marginRight: theme.spacing(2),
        // lineHeight: 1.6
    },
    menu: {
        position: 'relative',
        top: 5,
        [theme.breakpoints.down('sm')]: {
            top: -2,
            left: 10
        },
    }
}));

const AdminHeaderLayout = () => {
    const classes = useStyles();

    const userData = useSelector(userDataSelector)

    const role = userData.role;

    const preventDefault = (event) => event.preventDefault();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <AppBar position="fixed">
            <Toolbar>
                {screenWidth < 800 && <Grid container spacing={1}>
                    <Grid item xs={1} style={{minWidth: 100}}>
                        <Typography className={classes.logoText}>
                            Perolite<br />App
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "left" }}>
                        <Button aria-controls="simple-menu" align='right' aria-haspopup="true" onClick={handleClick} className={classes.button}>
                            МЕНЮ
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>
                                <Link href="/admin/dashboard" className={classes.linkMob}>
                                    Дашборд
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/admin/allPromocodes" className={classes.linkMob}>
                                    Всі промокоди
                                </Link>
                            </MenuItem>
                            {/* <MenuItem onClick={handleClose}>
                                <Link href="/admin/doctorsBonusReport" className={classes.linkMob}>
                                    Архів бонусів лікарів
                                </Link>
                            </MenuItem> */}
                            <MenuItem onClick={handleClose}>
                                <Link href="/admin/orderListReport" className={classes.linkMob}>
                                    Замовлення
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/admin/productQtyListReport" className={classes.linkMob}>
                                    План
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/admin/debetListReport" className={classes.linkMob}>
                                    Дебет
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/admin/orderForm2" className={classes.linkMob}>
                                    Форма
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/admin/orderFormFeetcalm" className={classes.linkMob}>
                                    Форма-F
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link href="/admin/bonusSystem" className={classes.linkMob}>
                                    Бонуси
                                </Link>
                            </MenuItem>
                            {role === 'admins' && <MenuItem onClick={handleClose}>
                                <Link href="/admin/bonusSystemPayments" className={classes.linkMob}>
                                    Оплати бонусів
                                </Link>
                            </MenuItem>}
                            <MenuItem onClick={handleClose}>
                                <Link href="/"
                                    className={classes.linkMob}
                                    onClick={() => localStorage.removeItem('user')}
                                >
                                    Вийти
                                </Link>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                }
                {screenWidth > 800 &&
                    <Grid container spacing={1}>
                        <Grid item xs={1} md={1}>
                            <Typography className={classes.logoText}>
                                Perolite<br />App
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={8} className={classes.menu}>
                            <Link href="/admin/dashboard" className={classes.link}>
                                Дашборд
                            </Link>
                            <Link href="/admin/allPromocodes" className={classes.link}>
                                Всі промокоди
                            </Link>
                            {/* <Link href="/admin/doctorsBonusReport" className={classes.link}>
                                Архів бонусів лікарів
                            </Link> */}
                            <Link href="/admin/orderListReport" className={classes.link}>
                                Замовлення
                            </Link>
                            <Link href="/admin/productQtyListReport" className={classes.link}>
                                План
                            </Link>
                            <Link href="/admin/debetListReport" className={classes.link}>
                                Дебет
                            </Link>
                            <Link href="/admin/orderForm2" className={classes.link}>
                                Форма
                            </Link>
                            <Link href="/admin/orderFormFeetcalm" className={classes.link}>
                                Форма-F
                            </Link>
                            <Link href="/admin/bonusSystem" className={classes.link}>
                                Бонуси
                            </Link>
                            {role === 'admins' && <Link href="/admin/bonusSystemPayments" className={classes.link}>
                                Оплати бонусів
                            </Link>}
                            <Link href="/"
                                className={classes.link}
                                onClick={() => localStorage.removeItem('user')}
                            >
                                Вийти
                            </Link>
                        </Grid>
                    </Grid>
                }
            </Toolbar>
        </AppBar>
    )
}

export default AdminHeaderLayout