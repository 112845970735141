export const yearList = [2025, 2024, 2023, 2022, 2021];
export const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const numberOfMonths = 4;
export const monthNameList = [
  { month: '01', name: 'Січень' },
  { month: '02', name: 'Лютий' },
  { month: '03', name: 'Березень' },
  { month: '04', name: 'Квітень' },
  { month: '05', name: 'Травень' },
  { month: '06', name: 'Червень' },
  { month: '07', name: 'Липень' },
  { month: '08', name: 'Серпень' },
  { month: '09', name: 'Вересень' },
  { month: '10', name: 'Жовтень' },
  { month: '11', name: 'Листопад' },
  { month: '12', name: 'Грудень' }
]