import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import PromocodeBonusReadRow from './PromocodeBonusReadRow';
import PromocodeBonusEditableRow from './PromocodeBonusEditableRow';
import { updatePromocodeItemAction, updatePromocodeBonusAction } from '../data/actions/promocodeActions';
import { ACTIVE_PROMOCODE_STATUS, NEW_PROMOCODE_STATUS } from '../data/constants/statuses';
import AdminPromocodeBonusReadRow from './AdminPromocodeBonusReadRow';
import { yearList } from '../data/constants/dates';

const useStyles = makeStyles({
  table: {
    minWidth: 1000,
    position: 'relative',
    top: -11
  },
  container: {
    maxHeight: 1000,
    padding: '0 0 10px',
    overflow: 'visible',
    marginTop: 122
  },
  stickyHeader: {
    backgroundColor: '#e8ebf7',
    position: "sticky",
    top: 112,
    zIndex: 100,
    boxShadow: '1px 1px 1px #ccc'
  },
  formControl: {
    width: 140,
    marginLeft: 10
  },
  filterRow: {
    backgroundColor: '#e8ebf7',
    width: '100%',
    display: 'inline-block',
    position: 'fixed',
    top: 64,
    zIndex: 101,
    borderBottom: '1px solid #ccc'
  },
  totalSumStyle: {
    backgroundColor: '#e0f0ff',
    color: '#000',
    fontWeight: 700
  }
});

const AdminViewTableDoctorsBonusList = (props) => {
  const {
    items,
    month,
    year,
    managerId,
    managerList,
    location,
    setLocation,
    archiveStatus,
    userData,
    handleChangeMonth,
    handleChangeYear,
    handleChangeManager,
    handleChangeArchive
  } = props

  const classes = useStyles();

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  }

  return (
    <>
      <div className={classes.filterRow}>
        <FormControl className={classes.formControl}>
          <InputLabel id="month label">Місяць</InputLabel>
          <Select
            value={month}
            onChange={handleChangeMonth}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year label">Рік</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
          >
            {yearList.map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="manager label">Менеджер</InputLabel>
          <Select
            value={managerId}
            onChange={handleChangeManager}
          >
            {managerList.map(item => {
              return <MenuItem value={item.id}>{item.managerName}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="archive label">Статус бонусів</InputLabel>
          <Select
            value={archiveStatus}
            onChange={handleChangeArchive}
          >
            <MenuItem value={true}>Архів бонусів</MenuItem>
            <MenuItem value={false}>Поточні бонуси</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className={classes.container} elevation={0}>
        <Table className={classes.table} size="small" aria-label="a dense table" >
          <TableHead className={classes.stickyHeader}>
            <TableRow>
              <TableCell>Менеджер</TableCell>
              <TableCell>Промокод</TableCell>
              <TableCell>ПІБ лікаря</TableCell>
              <TableCell>Клініка</TableCell>
              <TableCell>Місто</TableCell>
              <TableCell>Область</TableCell>
              <TableCell>Номер телефону</TableCell>
              <TableCell>Номер карти</TableCell>
              <TableCell>Місяць</TableCell>
              <TableCell>Бонуси по промокодам</TableCell>
              <TableCell>Дод. бонуси</TableCell>
              <TableCell className={classes.totalSumStyle}>Сума всіх бонусів</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.sort((a, b) => (b.bonusSum - a.bonusSum)).map(item => {
              // console.log("🚀 ~ file: ViewTableDoctorsBonusList.js ~ line 108 ~ ViewTableDoctorsBonusList ~ item", item)
              return (
                <AdminPromocodeBonusReadRow
                  item={item}
                  itemId={item.id}
                />
              )
            }
            )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AdminViewTableDoctorsBonusList