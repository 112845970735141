import React, { useState, useEffect } from 'react';
import SummaryPanel from './SummaryPanel';
import DataTable from './DataTable';
import FilterPanel from './FilterPanel';
import { useSelector } from 'react-redux';
import { getBonusDataByByYearAndMonthAndRegion, getOrderDataByYearAndMonthAndRegion, getTotalSumsByYearAndMonth } from '../../data/selectors/bonusSystemSelector';
import { Paper } from '@material-ui/core';
import ItemCard from './ItemCard';
import FilterItemPanel from './FilterItemPanel';

const EditBonusItem = ({
  userData,
  yearFilter,
  setYearFilter,
  monthFilter,
  setMonthFilter,
  doctorFilter,
  setDoctorFilter,
  filteredItems,
  selectedItem,
  promocodeFilter,
  setPromocodeFilter,
  saleQtySum,
  salePriceSum,
  productsByQuantity,
  selectedBonusItem,
  previousMonths,
  selectedData,
  promocodeComments,
  brand,
  handleChangeBrand
}) => {
  
  const [isSaved, setIsSaved] = useState(false);

  return (
    <Paper elevation={0}>
      <Paper style={{ position: 'fixed', width: '100%', top: '64px', backgroundColor: '#fff', border: '1px solid #ccc', zIndex: 10 }}>
        <FilterItemPanel
          yearFilter={yearFilter}
          monthFilter={monthFilter}
          setYearFilter={setYearFilter}
          setMonthFilter={setMonthFilter}
          doctorFilter={doctorFilter}
          setDoctorFilter={setDoctorFilter}
          filteredItems={filteredItems}
          promocodeFilter={promocodeFilter}
          setPromocodeFilter={setPromocodeFilter}
          brand={brand}
          handleChangeBrand={handleChangeBrand}
        />
      </Paper>
      {selectedItem && < ItemCard
        userData={userData}
        selectedItem={selectedItem}
        saleQtySum={saleQtySum}
        salePriceSum={salePriceSum}
        productsByQuantity={productsByQuantity}
        selectedBonusItem={selectedBonusItem}
        previousMonths={previousMonths}
        selectedData={selectedData}
        promocodeComments={promocodeComments}
        setDoctorFilter={setDoctorFilter}
        setIsSaved={setIsSaved}
        isSaved={isSaved}
      />
      }
    </Paper>
  );
};

export default EditBonusItem;
